import { Component, ChangeDetectionStrategy, Input, OnInit } from '@angular/core';
import { IncomingVideoCallAction } from '@features/video-calls/enums/incoming-video-call-action.enum';
import { VideoCall } from '@features/video-calls/models/video-call.interface';
import { VideoCallService } from '@features/video-calls/services/video-call.service';
import { IonButton, IonLabel, ModalController } from '@ionic/angular/standalone';
import { People } from '@shared/api';
import { PersonIconComponent } from '@common/components/person-icon/person-icon.component';
import { PersonNameComponent } from '@common/components/person-name/person-name.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'cc-incoming-video-call-modal',
    templateUrl: './incoming-video-call-modal.component.html',
    styleUrls: ['./incoming-video-call-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [PersonIconComponent, PersonNameComponent, TranslateModule, IonButton, IonLabel]
})
export class IncomingVideoCallModalComponent implements OnInit {
    @Input()
    public incomingCall: VideoCall;

    public person: People;

    constructor(
        private modalController: ModalController,
        private videoCallService: VideoCallService
    ) {}

    public ngOnInit(): void {
        // We need a people object for the cc-profile-icon component to work.
        this.person = {
            first_name: this.incomingCall.first_name,
            last_name: this.incomingCall.last_name,
            app_picture: this.incomingCall.profile_picture
        } as People;
    }

    public accept(): void {
        // We need to do this directly from a user click event.
        this.videoCallService.openNewWindow();
        this.modalController.dismiss(IncomingVideoCallAction.Accept);
    }

    public decline(): void {
        this.modalController.dismiss(IncomingVideoCallAction.Decline);
    }

    public block(): void {
        this.modalController.dismiss(IncomingVideoCallAction.Block);
    }
}
